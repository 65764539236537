import React from "react";
import { Alert, AlertProps } from "@mui/material";

export type MhcAlertProps = AlertProps;

export const MhcAlert: React.FC<MhcAlertProps> = ({ variant = "standard", ...props }) => {
  return (
    <Alert variant={variant} {...props}>
      {props.children}
    </Alert>
  );
};
