import { ApolloClient } from "@apollo/client";

import inMemoryCache from "../client/cache/legacy";
import apolloClientLink from "../client/link";

/**
 * @deprecated The method should not be used
 */
export const graphqlClient = new ApolloClient({
  link: apolloClientLink,
  cache: inMemoryCache,
  connectToDevTools: process.env.ENVIRONMENT !== "production"
});
