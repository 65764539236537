"use client";

import "leaflet/dist/leaflet.css";

import { useMemo } from "react";
import dynamic from "next/dynamic";

import { MhcMapFeature, MhcMapFeatureCollection, MhcMapProperties } from "graphqlApi/types";

import { useIsMobile } from "common/util/hooks/useIsMobile";

import { BaseMapProps } from "common/components/GeoMap/BaseMap";
import { StateLoadingIndicator } from "./StateLoadingIndicator";

export type GeoMapV2Props = BaseMapProps<MhcMapFeatureCollection, MhcMapFeature, MhcMapProperties>;

const GeoMapV2: React.FC<GeoMapV2Props> = ({ width = "100%", height = "100%", ...props }) => {
  const isMobile = useIsMobile();
  const Component = useMemo(
    () =>
      dynamic(async () => import("common/components/GeoMap/BaseMapV2"), {
        ssr: false,
        loading: () => (
          <StateLoadingIndicator
            loading={true}
            width={width}
            height={height}
            indicatorHeight={props.loadingIndicator?.height}
            sx={props.loadingIndicator?.sx}
            wrapperSx={{
              ...props.loadingIndicator?.wrapperSx,
              minHeight: isMobile ? props.minHeight : undefined
            }}
          />
        )
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.externallySelectedFeatureId, props.geoJSON]
  );
  return <Component width={width} height={height} {...props} />;
};

export default GeoMapV2;
