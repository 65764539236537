import { ticks } from "d3";
import { ScaleLinear, scaleLinear } from "d3-scale";

const colorRangeNames = [
  "blueYellow",
  "greenPurple",
  "orangeGreen",
  "brownGreen",
  "greenOrange"
] as const;
export type ColorRangeName = (typeof colorRangeNames)[number];

type ColorRange = [string, string];
export const MAP_COLOR_RANGES: Record<ColorRangeName, ColorRange> = {
  blueYellow: ["#0868ac", "#f0f9e8"],
  greenPurple: ["#7fbf7b", "#af8dc3"],
  orangeGreen: ["#d8b365", "#5ab4ac"],
  greenOrange: ["#5ab4ac", "#d8b365"],
  brownGreen: ["#018571", "#a6611a"]
};

export const DEFAULT_MAP_COLOR_RANGE_NAME: ColorRangeName = "blueYellow";
export const DEFAULT_MAP_COLOR_RANGE = MAP_COLOR_RANGES[DEFAULT_MAP_COLOR_RANGE_NAME];

export type Domain = [number, number];
export type GetColor = () => string;

export type ScaleCallback = ((value: number) => string) | ScaleLinear<number, number, never>;

export const continuousColorScale = (
  [min, max]: Domain,
  { colorRangeName }: { colorRangeName: ColorRangeName } = {
    colorRangeName: DEFAULT_MAP_COLOR_RANGE_NAME
  }
): ScaleCallback => {
  const colorRange = MAP_COLOR_RANGES[colorRangeName];
  if (min === max) return () => colorRange[0] as string;
  return scaleLinear()
    .domain([max, min])
    .range(colorRange as Iterable<number>);
};

export const mapLegendTicks = ([min, max]: Domain, tickCount: number | undefined) => {
  return ticks(min, max, tickCount ?? 5);
};
