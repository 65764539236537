"use client";

import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from "react";
import isNil from "lodash/isNil";
import { useLazyQuery } from "@apollo/client";
import { graphqlClient } from "graphqlApi/legacy/client";

import { MappedDropdownOption } from "common/components/charts/Investigate/types";
import { InvestigateMapPropsV2 } from "common/components/InvestigateMap/V2/util/types";
import {
  MapDocument,
  MapQuery,
  MhcGeographyEnum,
  MhcMap,
  MhcMapFeatureCollection,
  MhcStatIdentifier
} from "graphqlApi/types";

import {
  convertLegendValuesToMinMax,
  identifierConfigsToMapLayerConfigs
} from "common/components/InvestigateMap/V2/util/converters";
import type { NoCountry } from "common/components/LocationSwitcher/util/groupAndTurnLocationsIntoFeatures";
import { getReadableGeographyName } from "common/util/geographyHelpers";
import { sortGeographiesBySize } from "common/util/sortGeographiesBySize";

import { MinMaxFeaturesProps } from "./updateMinMaxFromFeature";
import { useLocalOrOverride } from "./useLocalOverride";
import { useSiInvestigations } from "./useSiInvestigations";

type BaseProps = Pick<
  InvestigateMapPropsV2<MhcMapFeatureCollection>,
  | "omitGeographies"
  | "onSelectedGeographyChange"
  | "onSelectedStatChange"
  | "onSelectedStatIdChange"
  | "overrideDateByStatMap"
  | "overrideSelectedGeography"
  | "overrideSetSelectedGeography"
  | "statConfigs"
>;

interface UseMapTypeProps extends BaseProps {
  allowGeographyChange?: boolean;
  defaultGeography: MhcGeographyEnum;
  defaultStatId: string;
  geoJsonByGeography?: InvestigateMapPropsV2<MhcMapFeatureCollection>["initialGeoJsonByGeography"];
  initialMinMax: MinMaxFeaturesProps["initial"];
  layersHaveCommonLegend?: boolean;
  loadingGeography?: boolean;
  selectedLocationId?: string;
  setGeoJsonByGeography: (
    geoJsonByGeography: Partial<Record<MhcGeographyEnum, MhcMapFeatureCollection>>
  ) => void;
  setLoadingGeography: (nv: boolean) => void;
  setMinMaxRecord: (nv: MinMaxFeaturesProps["initial"]) => void;
  setSelectedGeoJson: Dispatch<SetStateAction<MhcMapFeatureCollection>>;
  stats: MhcStatIdentifier[];
  availableGeographies?: MhcGeographyEnum[];
}

type HandleDataFetchingOnUpdateProps = Pick<
  UseMapTypeProps,
  | "layersHaveCommonLegend"
  | "initialMinMax"
  | "loadingGeography"
  | "overrideDateByStatMap"
  | "selectedLocationId"
  | "setGeoJsonByGeography"
  | "setLoadingGeography"
  | "setMinMaxRecord"
  | "setSelectedGeoJson"
> & {
  geo: NoCountry;
  geoJsonByGeography?:
    | InvestigateMapPropsV2<MhcMapFeatureCollection>["initialGeoJsonByGeography"]
    | undefined;
  selectedStatId: string;
  statConfigs?: InvestigateMapPropsV2["statConfigs"];
  statIds: string[];
};

export const useMapType = ({
  availableGeographies: _availableGeographies,
  allowGeographyChange = true,
  defaultGeography,
  defaultStatId,
  geoJsonByGeography,
  initialMinMax,
  layersHaveCommonLegend,
  loadingGeography,
  omitGeographies,
  onSelectedGeographyChange,
  onSelectedStatChange,
  onSelectedStatIdChange,
  overrideDateByStatMap,
  overrideSelectedGeography,
  overrideSetSelectedGeography,
  selectedLocationId,
  setGeoJsonByGeography,
  setLoadingGeography,
  setMinMaxRecord,
  setSelectedGeoJson,
  statConfigs,
  stats
}: UseMapTypeProps) => {
  const [selectedGeography, setSelectedGeography] = useLocalOrOverride<MhcGeographyEnum>({
    defaultValue: defaultGeography,
    overrideSetValue: overrideSetSelectedGeography,
    overrideValue: overrideSelectedGeography
  });
  const {
    ids: statIds,
    selectedStatId,
    mappedOptions: siMappedOptions,
    setSelectedStatId
  } = useSiInvestigations({
    stats,
    defaultStatId,
    onSelectedStatIdChange
  });
  const [getMhcMap, {}] = useLazyQuery<MapQuery>(MapDocument, { client: graphqlClient });

  const selectedStat = useMemo(() => {
    const filtered = stats.filter((stat) => stat.id === selectedStatId);
    const selectedSi = filtered.length > 0 ? filtered[0] : null;
    onSelectedStatChange?.(selectedSi ?? undefined);
    return selectedSi;
  }, [onSelectedStatChange, selectedStatId, stats]);

  const availableGeographies = useMemo(() => {
    return (
      _availableGeographies ?? [...new Set(stats.flatMap((stat) => stat.availableGeographies))]
    );
  }, [stats, _availableGeographies]);

  const mappedOptions: MappedDropdownOption[] = useMemo(() => {
    if (!allowGeographyChange) return [];
    const geos = availableGeographies.filter((geo) =>
      omitGeographies ? !omitGeographies?.includes(geo) : true
    );
    return sortGeographiesBySize(geos)
      .map((geography) => {
        return {
          title: getReadableGeographyName(geography, true),
          value: geography
        };
      })
      .reverse();
  }, [availableGeographies, omitGeographies, allowGeographyChange]);

  const handleDataFetchingOnUpdate = useCallback(
    ({
      geo,
      geoJsonByGeography,
      loadingGeography,
      selectedLocationId,
      selectedStatId,
      setGeoJsonByGeography,
      setLoadingGeography,
      setMinMaxRecord,
      setSelectedGeoJson,
      statConfigs
    }: HandleDataFetchingOnUpdateProps) => {
      if (loadingGeography === true) return;
      if (!isNil(geoJsonByGeography)) {
        if (geoJsonByGeography && !isNil(geoJsonByGeography[geo])) {
          setSelectedGeoJson(geoJsonByGeography[geo]);
          return;
        }
      }
      // TODO: Setup a way to cache geographies and mapData?
      setLoadingGeography(true);
      const loadNewGeography = async () => {
        if (loadingGeography) return;
        const statConfig = statConfigs?.find(({ identifier }) => identifier === selectedStatId);
        let mapData: MhcMap | null = null;
        if (statConfig) {
          mapData = (
            await getMhcMap({
              variables: {
                geography: geo,
                layersHaveCommonLegend,
                layerConfigs: identifierConfigsToMapLayerConfigs({
                  configs: [statConfig],
                  locationId: selectedLocationId
                })
              }
            })
          )?.data?.map as MhcMap;
        } else {
          mapData = (
            await getMhcMap({
              variables: {
                geography: geo,
                layersHaveCommonLegend,
                layerConfigs: []
              }
            })
          )?.data?.map as MhcMap;
        }
        mapData.legend?.maxMinValues &&
          setMinMaxRecord(
            convertLegendValuesToMinMax({
              maxMinValues: mapData.legend?.maxMinValues,
              geography: geo
            })
          );
        if (mapData.featureCollection) {
          setSelectedGeoJson(mapData.featureCollection as MhcMapFeatureCollection);
          const current = { ...geoJsonByGeography };
          delete current[geo];
          current[geo] = mapData.featureCollection;
          setGeoJsonByGeography(current);
        }
      };
      void loadNewGeography().then(() => setLoadingGeography(false));
    },
    [getMhcMap, layersHaveCommonLegend]
  );

  const handleGeographyUpdate = (geo: NoCountry, statId: string) => {
    onSelectedGeographyChange?.(geo);
    setSelectedGeography(geo);
    const stat = stats.find((stat) => stat.id === statId);
    if (!stat?.availableGeographies.includes(geo)) {
      setSelectedStatId(
        stats.find((stat) => stat.availableGeographies.includes(geo))?.id ?? statId
      );
    } else setSelectedStatId(statId);
    handleDataFetchingOnUpdate({
      geo,
      geoJsonByGeography,
      initialMinMax,
      layersHaveCommonLegend,
      loadingGeography,
      overrideDateByStatMap,
      selectedLocationId,
      selectedStatId: statId,
      statIds,
      setGeoJsonByGeography,
      setLoadingGeography,
      setMinMaxRecord,
      setSelectedGeoJson,
      statConfigs
    });
  };

  const handleGeographyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const geo = event.target.value as NoCountry;
    handleGeographyUpdate(geo, selectedStatId);
  };

  const handleStatIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const statId = event.target.value as string;
    handleGeographyUpdate(selectedGeography as NoCountry, statId);
  };

  useEffect(() => {
    if (isNil(overrideSelectedGeography) || loadingGeography) return;
    handleDataFetchingOnUpdate({
      geo: overrideSelectedGeography as NoCountry,
      statIds,
      geoJsonByGeography,
      initialMinMax,
      loadingGeography,
      overrideDateByStatMap,
      selectedLocationId,
      statConfigs,
      selectedStatId,
      setGeoJsonByGeography,
      setLoadingGeography,
      setMinMaxRecord,
      setSelectedGeoJson
    });
  }, [
    geoJsonByGeography,
    handleDataFetchingOnUpdate,
    initialMinMax,
    loadingGeography,
    overrideDateByStatMap,
    overrideSelectedGeography,
    selectedLocationId,
    selectedStatId,
    statConfigs,
    setGeoJsonByGeography,
    setLoadingGeography,
    setMinMaxRecord,
    setSelectedGeoJson,
    stats,
    statIds
  ]);

  return {
    mappedOptions,
    selectedGeography: overrideSelectedGeography ? overrideSelectedGeography : selectedGeography,
    handleGeographyUpdate,
    handleGeographyChange,
    selectedStatId,
    handleStatIdChange,
    statIds,
    siMappedOptions,
    selectedStat
  };
};
