"use client";

import uniqueId from "lodash/uniqueId";

import { MinMaxValue } from "../util/types";
import { MhcStatIdentifier } from "graphqlApi/types";

import { ColorRangeName, MAP_COLOR_RANGES } from "../../GeoMap/utils";

import { GeoMapLegend } from "../../GeoMap/Legend/GeoMapLegend";

export interface InvestigateMapLegendWrapperV2Props {
  selectedSi?: MhcStatIdentifier;
  minMax: MinMaxValue;
  colorRangeName?: ColorRangeName;
}

export const InvestigateMapLegendWrapperV2 = ({
  selectedSi,
  minMax,
  colorRangeName
}: InvestigateMapLegendWrapperV2Props) => {
  const subtitle = selectedSi && selectedSi.subtitle;

  const title = (subtitle || selectedSi?.name) ?? undefined;
  return (
    <>
      {title && (
        <GeoMapLegend
          colors={colorRangeName ? MAP_COLOR_RANGES[colorRangeName] : undefined}
          width={"130px"}
          id={selectedSi?.id ?? uniqueId()}
          legendTitle={title}
          isClipped={false}
          isPercent={selectedSi?.isPercent}
          isCurrency={selectedSi?.unit === "dollars"}
          {...minMax}
        />
      )}
    </>
  );
};
