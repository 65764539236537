"use client";

import { useCallback, useMemo, useState } from "react";
import isNil from "lodash/isNil";

interface UseLocalOrOverrideProps<T> {
  overrideValue?: T;
  overrideSetValue?: (nv: T) => void;
  defaultValue?: T;
}

/**
 * Use the local state or override with an external value from the parent component
 *
 * @param props - configuration object
 * @param props.overrideValue - Optional value to override from the parent component
 * @param props.overrideSetValue - Optional value setter override from the parent component
 * @param props.defaultValue - Default value for the state of the hook, only utilized if overrideValue and overrideSetValue are not defined
 * @returns Hook for use instead of useState
 */
export const useLocalOrOverride = <T>({
  overrideValue,
  overrideSetValue,
  defaultValue
}: UseLocalOrOverrideProps<T>): [T | undefined, (nv: T) => void] => {
  const [value, setValue] = useState<T | undefined>(defaultValue);

  const _setValue = useCallback(
    (nv: T) => {
      if (!isNil(overrideSetValue)) {
        overrideSetValue(nv);
        return;
      }
      setValue(nv);
    },
    [overrideSetValue]
  );

  const _value = useMemo(() => {
    if (!isNil(overrideValue)) return overrideValue;
    return value;
  }, [overrideValue, value]);

  return [_value, _setValue];
};
