import { InMemoryCache } from "@apollo/client";

import { inMemoryCacheConfig } from "./shared";

/**
 * @deprecated The method should not be used
 */
const inMemoryCache = new InMemoryCache(inMemoryCacheConfig);

export default inMemoryCache;
