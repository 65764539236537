"use client";

import React, { ReactElement, useCallback, useMemo, useState } from "react";
import { Box, BoxProps, Collapse, Typography } from "@mui/material";
import isNil from "lodash/isNil";

import { useIsMobile } from "common/util/hooks/useIsMobile";

import ContinuousLegend, { LegendSize, LegendSwatch } from "../ContinuousLegend";
import { MAP_SWATCH_BOX_SHADOW, noDataMapColor } from "../mapStyles";
import { LegendTitle } from "./LegendTitle";

export const legendBoxStyle: BoxProps["sx"] = {
  background: "#F8F9F9",
  boxShadow:
    "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)",
  borderRadius: "10px",
  padding: "6px 10px",
  gap: "6px",
  mr: "20px",
  mb: "20px"
};

export interface LegendItem {
  id?: string;
  color?: string;
  borderColor?: string;
  title: React.ReactNode;
}

export interface GeoMapLegendProps {
  colors?: [string, string];
  id: string;
  isClipped?: boolean;
  isCurrency?: boolean;
  isPercent?: boolean;
  items?: LegendItem[];
  legendTitle?: string;
  maxValue?: number | null;
  minValue?: number | null;
  precision?: number;
  showMaxTickValue?: boolean;
  showZeroWatch?: boolean;
  width?: string;
  swatches?: ReactElement[];
}

export const GeoMapLegend: React.FC<GeoMapLegendProps> = ({
  id,
  colors,
  isClipped = false,
  isCurrency = false,
  isPercent = false,
  items = [],
  legendTitle,
  maxValue,
  minValue,
  precision,
  showMaxTickValue = true,
  showZeroWatch = false,
  width = "125px",
  swatches
}) => {
  const isMobile = useIsMobile();
  const [collapsed, setCollapsed] = useState(false);
  const mobileInfoBoxStyle: BoxProps["sx"] = isMobile
    ? {
        top: 0,
        bottom: "auto !important",
        m: "15px !important"
      }
    : {};

  const useContinuousScale = !isNil(minValue) && !isNil(maxValue) && items.length === 0;
  const size = useMemo((): LegendSize => ({ width: 40, height: 200, bar: 20 }), []);

  const handleCollapse = useCallback(() => {
    setCollapsed((prev) => !prev);
  }, []);

  return (
    <Box
      className="info leaflet-bottom leaflet-right"
      sx={{
        ...legendBoxStyle,
        maxWidth: width,
        ...mobileInfoBoxStyle
      }}
    >
      {legendTitle && <LegendTitle title={legendTitle} handleCollapse={handleCollapse} />}
      <Collapse in={!collapsed}>
        {useContinuousScale && (
          <ContinuousLegend
            id={id}
            isClipped={isClipped}
            isCurrency={isCurrency}
            isPercent={isPercent}
            max={maxValue}
            min={minValue}
            precision={precision}
            showZeroWatch={showZeroWatch}
            tickCount={6}
            swatches={swatches}
            showMaxTickValue={showMaxTickValue}
            colors={colors}
          />
        )}
        {!useContinuousScale && items.length > 0 && (
          <>
            {items.map((item, index) => {
              return (
                <Box key={item?.id ?? index} sx={{ display: "flex", flexDirection: "row" }}>
                  {item.color && (
                    <Box
                      sx={{
                        width: "15px",
                        height: "15px",
                        backgroundColor: item.color,
                        border: item.borderColor ? `1px solid ${item.borderColor}` : undefined,
                        boxShadow: MAP_SWATCH_BOX_SHADOW,
                        mr: "9px",
                        my: "auto"
                      }}
                    ></Box>
                  )}
                  <Typography
                    fontSize="11.2px"
                    fontWeight={400}
                    lineHeight="24px"
                    color={({ palette }) => palette.light.primary}
                  >
                    {item.title}
                  </Typography>
                </Box>
              );
            })}
          </>
        )}
        {!useContinuousScale && items.length === 0 && (
          <LegendSwatch background={noDataMapColor} size={size.bar}>
            Insufficient
            <br />
            data
          </LegendSwatch>
        )}
      </Collapse>
    </Box>
  );
};
