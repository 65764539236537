import { PathOptions } from "leaflet";

import { demographicColors } from "theme/colors";

export const zeroMapColor = "#FFFFFF";
export const noDataMapColor = "#CCCCCC";

export const polygonStrokeStyle = {
  color: "#000000",
  weight: 1.5,
  opacity: 0.35,
  strokeLocation: "inside"
};

export const basicPolygonFeatureStyle: PathOptions = {
  fillColor: "#147BB1",
  // circleMarkers can overlap polygons, avoid opacity
  // fillOpacity: 0.2,
  fillOpacity: 1,
  ...polygonStrokeStyle
};

export const locationPickerNoValueStyle: PathOptions = {
  fillOpacity: 0.2,
  fillColor: "rgb(20, 123, 177)",
  ...polygonStrokeStyle,
  color: demographicColors.race.asian
};

export const polygonFeatureWithoutDataStyle: PathOptions = {
  fillColor: "rgba(0,0,0,.25)",
  fillOpacity: 0.5,
  ...polygonStrokeStyle
};

export const activePolygonFeatureWithoutDataStyle: PathOptions = {
  fillColor: "#0260f0",
  fillOpacity: 1,
  ...polygonStrokeStyle,
  color: "#0260f0",
  opacity: 1
};

export const selectedPolygonFeatureStyle: PathOptions = {
  weight: 3,
  opacity: 0.5,
  color: "#C24644",
  dashArray: "",
  fillOpacity: 1
  // circleMarkers can overlap polygons, avoid opacity
  // fillOpacity: 0.5
};

export const selectedPolygonFeatureFillStyle: PathOptions = {
  fillOpacity: 1,
  fillColor: "#147BB1"
};

export const missingPolygonFeatureStyle: PathOptions = {
  fillColor: noDataMapColor,
  ...polygonStrokeStyle
};

export const featuredPolygonFeatureStyle: PathOptions = {
  weight: 1,
  opacity: 0.5,
  color: "#C24644",
  dashArray: "",
  fillColor: "#147BB1",
  fillOpacity: 0.5
};

export const blues = [
  "#e3eef9",
  "#cfe1f2",
  "#b5d4e9",
  "#93c3df",
  "#6daed5",
  "#4b97c9",
  "#2f7ebc",
  "#1864aa",
  "#0a4a90",
  "#08306b"
];

export const decimalFeatureStyles: PathOptions[] = blues.map((fillColor) => {
  return {
    fillOpacity: 1,
    fillColor
  };
});

export const MAP_SWATCH_BOX_SHADOW =
  "inset 1px -1px 0px 0px rgba(0,0,0,0.25), inset -1px 1px 0px 0px rgba(0,0,0,0.25)";
