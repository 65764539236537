import { MhcGeographyEnum } from "graphqlApi/types";

import { STATE_LOCATION_NAME } from "../constants";

export const geographyName = (
  geo: MhcGeographyEnum,
  { pluralize }: { pluralize?: boolean } = { pluralize: false }
): string => {
  const addS = (() => (pluralize ? "s" : ""))();
  switch (geo) {
    case MhcGeographyEnum.State:
      return STATE_LOCATION_NAME;
    case MhcGeographyEnum.County:
      return pluralize ? "Counties" : "County";
    case MhcGeographyEnum.ZipCode:
      return `Zip Code${addS}`;
    case MhcGeographyEnum.SchoolDistrict:
      return `School District${addS}`;
    case MhcGeographyEnum.CensusTract:
      return `Census Tract${addS}`;
    case MhcGeographyEnum.CensusBlockGroup:
      return `Census Block Group${addS}`;
    case MhcGeographyEnum.HouseOfRepDistrict:
      return `House Of Representatives District${addS}`;
    case MhcGeographyEnum.Municipality:
      return pluralize ? "Municipalities" : "Municipality";
    case MhcGeographyEnum.Neighborhood:
      return `Neighborhood${addS}`;
    case MhcGeographyEnum.SenateDistrict:
      return `Senate District${addS}`;
    case MhcGeographyEnum.Country:
      return pluralize ? "Countries" : "Country";
    default:
      return "Geography Unknown";
  }
};

export const getReadableGeographyName = (
  geography: MhcGeographyEnum,
  pluralize?: boolean
): string => {
  return geographyName(geography, { pluralize: pluralize ?? false });
};

export const getGeographyEnumFromReadableName = (name: string): MhcGeographyEnum | undefined => {
  const _name = name.toLowerCase();
  const prefixMappings: Record<string, MhcGeographyEnum> = {
    "census tract": MhcGeographyEnum.CensusTract,
    count: MhcGeographyEnum.County,
    school: MhcGeographyEnum.SchoolDistrict,
    state: MhcGeographyEnum.State,
    zip: MhcGeographyEnum.ZipCode,
    "census block": MhcGeographyEnum.CensusBlockGroup,
    house: MhcGeographyEnum.HouseOfRepDistrict,
    munici: MhcGeographyEnum.Municipality,
    neighborhood: MhcGeographyEnum.Neighborhood,
    senate: MhcGeographyEnum.SenateDistrict,
    country: MhcGeographyEnum.Country
  };
  for (const prefix in prefixMappings) {
    if (_name.startsWith(prefix)) {
      return prefixMappings[prefix];
    }
  }
  return undefined;
};

export const pluralizeReadableName = (name: string) => {
  const geo = getGeographyEnumFromReadableName(name);
  const rtValue = geo ? geographyName(geo, { pluralize: true }) : name;
  return rtValue;
};

export const geographyKeys = (): string[] => Object.keys(MhcGeographyEnum);
