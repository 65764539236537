"use client";

import { ReactElement, useEffect, useMemo, useRef } from "react";
import { Box, Typography } from "@mui/material";
import { axisRight, format, select } from "d3";
import { scaleLinear } from "d3-scale";

import { fontFamily } from "theme/theme";
import { DEFAULT_MAP_COLOR_RANGE } from "./utils";
import { formatValueByUnit } from "common/util/formatHelpers";

import { MAP_SWATCH_BOX_SHADOW, noDataMapColor } from "./mapStyles";

type LegendDirection = "min-to-max" | "max-to-min";

interface ContinuousLegendProps {
  colors?: string[];
  direction?: LegendDirection;
  id: string;
  isClipped?: boolean;
  isCurrency?: boolean;
  isPercent?: boolean;
  max: number;
  min: number;
  noDataColor?: string;
  precision?: number;
  showMaxTickValue?: boolean;
  showNoDataSwatch?: boolean;
  showZeroWatch?: boolean;
  swatches?: ReactElement[];
  tickCount?: number;
}

export const LegendSwatch: React.FC<{
  background?: string;
  size?: number;
  children: React.ReactNode;
}> = ({ background = "white", size = 20, children }) => (
  <Box display="flex" alignItems="center">
    <Box
      minHeight={size}
      minWidth={size}
      sx={{
        background,
        boxShadow: MAP_SWATCH_BOX_SHADOW
      }}
    />
    <Box sx={{ width: "10px", height: "1px", mr: "2px", background: "rgba(0,0,0,.25)" }} />
    <Typography sx={{ whiteSpace: "nowrap", lineHeight: 1 }} variant="body3">
      {children}
    </Typography>
  </Box>
);

interface D3LegendParams {
  id: string;
  svgElement: SVGElement;
  min: number;
  max: number;
  options: {
    colors?: string[];
    direction?: LegendDirection;
    isClipped?: boolean;
    isPercent?: boolean;
    isCurrency?: boolean;
    precision?: number;
    size?: { width: number; height: number; bar: number };
    showMaxTickValue?: boolean;
    tickCount: number;
  };
}

const renderD3Legend = ({ id, min, max, svgElement, options }: D3LegendParams) => {
  // Resources/References:
  // https://observablehq.com/@tmcw/d3-scalesequential-continuous-color-legend-example
  const {
    colors = DEFAULT_MAP_COLOR_RANGE,
    direction = "max-to-min",
    isClipped = false,
    isPercent = false,
    isCurrency = false,
    precision = 0,
    size = { width: 60, height: 200, bar: 30 },
    showMaxTickValue = true,
    tickCount
  } = options;
  const domain = isPercent ? [max / 100, min / 100] : [max, min];
  if (direction === "min-to-max") {
    domain.reverse();
  }

  // In some cases, the normal standard tickCount isn't appropiate for the precision and the range.
  // This results in the incorrect visualization of the legend for this stat identifiers. Thus, we do an additional calculation
  // where we calculate the smallest tick interval (STI), by obtaining the inverse value to the configured precision. Which then is
  // compared to the max value of the legend range. If the max value is smaller than the tick count * STI, then the tick count is
  // adjusted to the rounded number of the max value divided by the STI.
  let _tickCount = tickCount;
  // Smallest tick interval = 1 / (10^precision);
  const STI = 1 / (10 ^ precision);
  if (max < tickCount * STI) {
    _tickCount = Math.round(max / STI) - 1;
  }

  // The range method *can* receive an array of strings but for some reason
  // is typed as Iterable<number>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const colorScale = scaleLinear().domain(domain).range(colors);
  const { width, height } = size;

  const marginY = 5;

  // Remove all elements in case the legend in re-rendered
  select(svgElement).selectAll("*").remove();

  // Create def for linear gradient based on the color scale
  // Used later to fill the rectangle that renders with the legend
  // There's opportunity to refactor this to use a ramp with `scaleSequential` like in this example:
  // https://observablehq.com/@d3/sequential-scales#appendix
  const defs = select(svgElement).append("defs");
  const linearGradient = defs
    .append("linearGradient")
    .attr("id", `linear-gradient-${id}`)
    .attr("x1", "0%")
    .attr("x2", "0%")
    .attr("y1", "0%")
    .attr("y2", "100%");

  // Add color stops to the linear gradient based on the color scale
  // similar example: https://www.visualcinnamon.com/2016/05/smooth-color-legend-d3-svg-gradient/
  linearGradient
    .selectAll("stop")
    .data(
      colorScale
        // Map through ticks and add a stop for each one with the appropriate offset and color
        .ticks()
        .map((t, i, n) => ({ offset: `${100 * (i / (n.length - 1))}%`, color: colorScale(t) }))
    )
    .enter()
    .append("stop")
    .attr("offset", (d) => d.offset)
    .attr("stop-color", (d) => d.color);

  // Create a scale for the axis based on desired height
  const axisScale = scaleLinear()
    .domain(colorScale.domain())
    .range([size.bar, height + (size.bar - marginY * 2)]);

  // Use D3's number formatting for tick labels
  // Had trouble finding the appropriate type here
  // Its a D3 selection wrapping a SVGElement group
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const tickFormat = (() => {
    switch (true) {
      case isPercent:
        // Precision ignored here because percentages in legend are rounded and neat
        return `.${precision > 0 && max > 0 && max < 1 ? precision : "00"}%`;
      case isCurrency:
        return `$,.${precision}f`;
      default:
        return `,.${precision}f`;
    }
  })();

  // Format the max value to be displayed in the legend since it's added manually
  // and not formatted by `tickFormat`
  let formattedMaxValue = "";
  formattedMaxValue = formatValueByUnit({
    value: max,
    precision,
    isPercent
  });
  isCurrency && (formattedMaxValue = `$${formattedMaxValue}`);
  isClipped && (formattedMaxValue = `${formattedMaxValue}+`);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const axis = (g: any) =>
    // Add style and basic attributes to group element wrapping axis
    g
      .attr("width", size.bar)
      .attr("height", height - marginY * 2)
      .attr("transform", `translate(${size.bar}, ${-size.bar + marginY})`)
      .style("font-size", "12px")
      .style("font-family", fontFamily)
      // Call the axis function on the group element to add axis to the group with ticks
      .call(
        axisRight(axisScale)
          // Add ticks to the axis based on the given tick count
          .ticks(_tickCount < 1 ? 3 : _tickCount)
          .tickSize(size.bar / 2)
          // format the tick labels
          .tickFormat(format(tickFormat))
      )
      // Modify the axis after it has been added to the group
      // Had trouble finding the appropriate type here
      // Its a D3 selection wrapping a SVGElement group
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .call((g: any) => {
        // Remove redundant and unnecessary elements of legend that D3 adds by default
        g.select(".domain").remove();

        const firstTick = g.select(".tick:first-child");
        // Create group for the max value line and text
        const maxLineGroup = g
          .append("g")
          .attr("transform", `translate(0, ${size.bar})`)
          .attr("class", "tick");
        // Manually add the max value tick to group
        maxLineGroup.append("line").attr("x2", "10").attr("stroke", "currentColor");

        // Add the max value tick to the legend if it won't be too close to the first tick
        let tickNearness = 0;
        const tickClosenessThreshold = 15;
        // Get positions of first tick and max value tick and calculate the distance between them
        if (maxLineGroup?.node() && firstTick?.node()) {
          const { y: maxLineY } = maxLineGroup.node().getBoundingClientRect();
          const { y: firstTickY } = firstTick.node().getBoundingClientRect();
          tickNearness = Math.abs(firstTickY - maxLineY);
        }
        // Add the max value text to the legend if there is enough space
        if (tickNearness > tickClosenessThreshold && formattedMaxValue) {
          maxLineGroup
            .append("text")
            .text(formattedMaxValue)
            .attr("transform", `translate(12, 5)`)
            .attr("fill", "black");
        }
        // If not, add the max value with a "+" to the existing first tick (if it exists)
        // and remove the max value tick since it's no longer necessary
        else if (
          isClipped &&
          firstTick?.node() &&
          tickNearness <= tickClosenessThreshold &&
          showMaxTickValue &&
          formattedMaxValue
        ) {
          // Get the existing value of the first tick and append `+` to it
          const textSelection = select(firstTick.node().parentElement).select("text");
          textSelection.text(`${textSelection.text()}+`);
          maxLineGroup.select("line").remove();
        }

        // Add the min value tick since it's not added by default
        const minLineGroup = g
          .append("g")
          .attr("transform", `translate(0, ${height + marginY * 2})`)
          .attr("class", "tick");
        minLineGroup.append("line").attr("x2", "10").attr("stroke", "currentColor");
        g.selectAll("line").style("opacity", ".3");
      });

  // Add the color gradient to the main svg element
  // The rectangle uses the linear-gradient def created above as its fill
  select(svgElement)
    .append("g")
    .append("rect")
    .attr("width", width / 2)
    .attr("height", height - marginY * 2)
    .attr("stroke", "#000000")
    .attr("stroke-location", "inside")
    .attr("stroke-opacity", ".25")
    .attr("transform", `translate(0, ${marginY})`)
    .style("fill", `url(#linear-gradient-${id})`);

  // Add the axis to the main svg element in a group
  select(svgElement).append("g").call(axis);
};

export interface LegendSize {
  width: number;
  height: number;
  bar: number;
}

const ContinuousLegend: React.FC<ContinuousLegendProps> = ({
  colors = DEFAULT_MAP_COLOR_RANGE,
  direction,
  id,
  isClipped = false,
  isCurrency = false,
  isPercent = false,
  max = 100,
  min = 0,
  noDataColor = noDataMapColor,
  precision = 0,
  showMaxTickValue = true,
  showNoDataSwatch = true,
  showZeroWatch = false,
  swatches = [],
  tickCount = 4
}) => {
  const legendRef = useRef(null);
  const hasManyDigits = useMemo(() => max.toString().length > 4, [max]);
  const size = useMemo((): LegendSize => ({ width: 40, height: 200, bar: 20 }), []);

  useEffect(() => {
    if (legendRef.current === null) return;
    renderD3Legend({
      svgElement: legendRef.current,
      min,
      max,
      id,
      options: {
        isClipped,
        isCurrency,
        isPercent,
        tickCount,
        size,
        colors,
        precision,
        direction,
        showMaxTickValue
      }
    });
  }, [
    colors,
    direction,
    id,
    isClipped,
    isCurrency,
    isPercent,
    max,
    min,
    precision,
    showMaxTickValue,
    size,
    tickCount
  ]);

  return (
    <Box display="flex" flexDirection="column" gap="5px" py={1}>
      {min !== max && !swatches?.length && (
        <svg ref={legendRef} width={size.width * (hasManyDigits ? 2.5 : 2)} height={size.height} />
      )}
      {min === max && (
        <LegendSwatch background={min === 0 ? "white" : colors[0]} size={size.bar}>
          {isCurrency ? "$" : ""}
          {min}
          {isPercent ? "%" : ""}
        </LegendSwatch>
      )}
      {swatches?.length > 0 && swatches}
      {min !== 0 && showZeroWatch && (
        <LegendSwatch background="#FFFFFF" size={size.bar}>
          0{isPercent ? "%" : ""}
        </LegendSwatch>
      )}
      {showNoDataSwatch && (
        <LegendSwatch background={noDataColor} size={size.bar}>
          Insufficient
          <br />
          data
        </LegendSwatch>
      )}
    </Box>
  );
};

export default ContinuousLegend;
