"use client";

import { useMemo, useState } from "react";

import { InvestigateMapPropsV2 } from "./types";
import { MappedDropdownOption } from "common/components/charts/Investigate/types";
import { MhcStatIdentifier } from "graphqlApi/types";

interface useSiInvestigationsProps {
  stats: MhcStatIdentifier[];
  defaultStatId: string;
  onSelectedStatIdChange: InvestigateMapPropsV2["onSelectedStatIdChange"];
}

export const useSiInvestigations = ({
  stats,
  defaultStatId,
  onSelectedStatIdChange
}: useSiInvestigationsProps) => {
  const [selectedStatId, setSelectedStatId] = useState<string>(defaultStatId);

  const ids = useMemo(() => {
    return stats.map((stat) => stat.id);
  }, [stats]);

  const mappedOptions: MappedDropdownOption[] = useMemo(() => {
    return stats.map((stat) => {
      return {
        title: stat.name,
        value: stat.id
      };
    });
  }, [stats]);

  const handleSiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const siId = event.target.value;
    setSelectedStatId(siId);
    onSelectedStatIdChange?.(siId);
  };

  return {
    ids,
    mappedOptions,
    selectedStatId,
    handleSiChange,
    setSelectedStatId
  };
};
